import React from "react"
import Layout from "../components/layout"
import { graphql as gql, PageProps } from "gatsby"
import useStoryblok from "../lib/storyblok"
import useStoryblokComponent from "../lib/useStoryblokComponent"
import Seo from "../components/seo"

const About: React.FC<PageProps<any>> = ({ data, location }) => {
  const story = useStoryblok(data.storyblokEntry, location)

  const StoryblokComponents = useStoryblokComponent(story)

  return (
    <Layout>
      <Seo
        title="About"
        description="Cytoki’s team of experienced executives combine deep expertise in IL-22 biology, lipidation technology and drug development to leverage the potential of IL-22 as a new treatment paradigm for metabolic diseases such as obesity and type 2 diabetes."
      />
      {StoryblokComponents}
    </Layout>
  )
}

export default About

export const query = gql`
  query About {
    storyblokEntry(full_slug: { eq: "about" }) {
      name
      content
    }
  }
`
